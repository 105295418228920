import React from "react";
import Logo from "../assets/img/loogo.png";
import CountUp from "react-countup";
import Residental1 from "../assets/img/ar3.jpg";
import Residental2 from "../assets/img/ar4.jpg";
import Residental3 from "../assets/img/ar5.jpg";
import Residental4 from "../assets/img/ar6.jpg";
import Residental5 from "../assets/img/ar7.jpg";
import Residental6 from "../assets/img/ar8.jpg";
import Residental7 from "../assets/img/ar9.jpg";
import Residental8 from "../assets/img/ar10.jpg";
import Residental9 from "../assets/img/ar11.jpg";
import Residental10 from "../assets/img/ar12.jpg";
import Residental11 from "../assets/img/ar13.jpg";
import Residental12 from "../assets/img/ar14.jpg";
import Residental13 from "../assets/img/ar15.jpg";
import Residental14 from "../assets/img/ar16.jpg";
import Residental15 from "../assets/img/ar17.jpg";
import Residental16 from "../assets/img/ar18.jpg";
import Residental17 from "../assets/img/ar19.jpg";
import Residental18 from "../assets/img/ar20.jpg";
import Residental19 from "../assets/img/ar21.jpg";
import Residental20 from "../assets/img/ar22.jpg";
import Residental21 from "../assets/img/ar23.png";
import Residental22 from "../assets/img/ar24.png";
import Residental23 from "../assets/img/ar25.jpg";
import Residental24 from "../assets/img/ar26.jpg";
import Residental25 from "../assets/img/ar27.jpg";
import Residental26 from "../assets/img/ar28.jpg";
import Residental27 from "../assets/img/ar29.jpg";
import Residental28 from "../assets/img/ar30.jpg";
import Residental29 from "../assets/img/ar31.jpg";
import Residental30 from "../assets/img/ar32.jpg";
import Residental31 from "../assets/img/ar33.jpg";
import Residental32 from "../assets/img/ar34.jpg";
import Residental33 from "../assets/img/ar35.jpg";
import Residental34 from "../assets/img/ar36.png";
import Residental35 from "../assets/img/ar37.jpg";
import Residental36 from "../assets/img/ar38.jpg";
import Residental37 from "../assets/img/ar39.jpg";
import Residental38 from "../assets/img/ar40.jpg";
import Residental39 from "../assets/img/ar2.jpg";
import Bujar from "../assets/img/bujar1.jpg";
import Sherif from "../assets/img/sherif1.jpg";
import Nimi from "../assets/img/nimi1.jpg";
import Liman from "../assets/img/liman1.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Post } from "../components/Post";

const AllPosts = [
  {
    image: Residental1,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental2,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental3,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental4,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental5,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental6,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental7,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'publike'
  },
  {
    image: Residental8,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te larta'
  },
  {
    image: Residental9,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te larta'
  },
  {
    image: Residental10,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te larta'
  },
  {
    image: Residental11,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te larta'
  },
  {
    image: Residental12,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental13,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental14,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },

  {
    image: Residental15,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental16,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental17,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental18,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental19,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental20,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental21,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental22,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental23,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental24,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental25,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental26,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental27,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental28,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental29,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental30,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental31,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental32,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental33,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental34,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental35,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental36,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental37,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental38,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  },
  {
    image: Residental39,
    text1: 'Apartments L2, 2008',
    text2: '3,000 m2, Pristina, Kosovo',
    category: 'te uleta'
  }
]

export const Index = () => {

  const [selectedFilter, setSelectedFilter] = useState('all')

  const [showingPosts, setShowingPosts] = useState(AllPosts)

  const goToHeader = () => {
    window.scrollTo(0, 0);
  };

  const getActiveClassOfFilters = (name) => {
    return name === selectedFilter && 'filter-active'
  }

  const changeStatusOfSelected = (name) => {
    setSelectedFilter(name)
  }

  useEffect(() => {
    // if (selectedFilter == 'all') {
    //   setShowingPosts(AllPosts)
    //   return
    // }

    // const posts = AllPosts.map(post=>post.category === selectedFilter)
    // setShowingPosts(posts)

  }, [selectedFilter])

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <a className="" onClick={goToHeader}>
              <img src={Logo} className="img-fluid logo1" alt="" />
            </a>
          </h1>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Ballina
                </a>
              </li>

              <li>
                <a className="nav-link scrollto" href="#services">
                  Shërbimet
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#team">
                  Rreth nesh
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Kontakti
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <div className="row">
            <div className="col-xl-6">
              <h1>Kompani ndërtimi që nga viti 2000</h1>
              <h2>
                Ndërtuar mbi vlera dhe parime, duke ofruar gjithmonë sherbime
                kualitative
              </h2>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="clients" className="clients">
          <div className="container" data-aos="zoom-in">
            <div className="clients-slider swiper">
              <div className="swiper-wrapper align-items-center d-flex flex-wrap"></div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section>
        <section id="about" className="about section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="content col-xl-5 d-flex align-items-stretch">
                <div className="content">
                  <h3>Ndërtime të larta, të mesme dhe të ulëta</h3>
                  <p>
                    Me teknologji bashkohore, mjete të punes dhe fuqi puntore ne
                    ndertuam për vite si në institucione publike ashtu dhe
                    private.
                  </p>
                  <a href="#contact" className="about-btn">
                    <span>Kontakto</span>{" "}
                    <i className="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-7 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <i className="bx bx-receipt"></i>
                      <h4>Ndërtime afaristo-banesore</h4>
                      <p>
                        Blloqet banesore të ndertuara nga ArbriaCom cilësohen
                        prej më kualitative në treg.
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <i className="bx bx-cube-alt"></i>
                      <h4>Ndërtime të spitaleve</h4>
                      <p>
                        Disa nga spitalet e Kosovës u ndërtuan nga kompania
                        jonë, duke përfunduar detyrat 100% në kohë.
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <i className="bx bx-images"></i>
                      <h4>Salla të sporteve dhe rekreacion</h4>
                      <p>
                        Disa salla të sportëve në Republikën e Kosovës u
                        ndërtuan nga ne, gjithashtu dhe parku industrial i
                        bizesit.
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <i className="bx bx-shield"></i>
                      <h4>Ndërtime të ulëta</h4>
                      <p>
                        Ne jemi te specializuar dhe ne ndertimin e ulet, pra
                        laxhe të kompletuara me infrastrukturë.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="count-box">
                  <i className="bi bi-emoji-smile"></i>
                  <CountUp end={313} />
                  <p>Klientë të kënaqur</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div className="count-box">
                  <i className="bi bi-journal-richtext"></i>
                  <CountUp end={27} />

                  <p>Projekte private</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-bank"></i>
                  <CountUp end={18} />
                  <p>Projekte publike</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-people"></i>
                  <CountUp end={106} />
                  <p>Të punësuar</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg ">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Sherbimet</h2>
              <p>
                Ndertimet tona kanë qëndrueshmëri dhe kjo na bën unik në treg.
                Sherbimet tona arsyetojnë investimin tuaj.
              </p>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="icon-box">
                  <i className="bi bi-hand-thumbs-up"></i>
                  <h4>
                    <a href="#">Pse ArberiaCom</a>
                  </h4>
                  <p>
                    Përvoj pune shumë vjeçare dhe traditë sinqeriteti ndaj
                    klientëve.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-card-checklist"></i>
                  <h4>
                    <a href="#">Çfarë ofrojmë</a>
                  </h4>
                  <p>
                    Sherbime kualitative në ndertim, objekte banesore dhe lokale
                    afariste. Kontrata afatgjata dhe kushte ideale për biznes.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-bar-chart"></i>
                  <h4>
                    <a href="#">Si qëndrojmë?</a>
                  </h4>
                  <p>
                    Statistikisht jemi cdohere kompani në rritje, duke pasur
                    klient aktive dhe të ripërsëritshme
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-1-square"></i>
                  <h4>
                    <a href="#">Lider në treg</a>
                  </h4>
                  <p>
                    Ne prijmë në treg për nga zhvillimet në ndërtime private dhe
                    publike, duke qenë lider të padiskutueshëm
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Portfolio</h2>
              <p>
                Ndertimet tona datojnë që nga viti 2000, duke vazhduar punën dhe
                rritur vazhdimisht. Disa nga punët tona janë:
              </p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" onClick={() => changeStatusOfSelected('all')} className={getActiveClassOfFilters('all')}>
                    Të gjitha
                  </li>
                  <li data-filter=".filter-app" onClick={() => changeStatusOfSelected('te larta')} className={getActiveClassOfFilters('te larta')}>Të larta</li>
                  <li data-filter=".filter-card" onClick={() => changeStatusOfSelected('publike')} className={getActiveClassOfFilters('publike')}>Publike</li>
                  <li data-filter=".filter-web" onClick={() => changeStatusOfSelected('te uleta')} className={getActiveClassOfFilters('te uleta')}>Të ulta</li>
                </ul>
              </div>
            </div>

            <div
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {showingPosts.map(post => {
                return <Post 
                image={post.image} 
                text1={post.text1} 
                text2={post.text2} 
                show={selectedFilter === 'all' || post.category === selectedFilter} />
              })}

            </div>
          </div>
        </section>

        <section id="team" className="team section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Rreth nesh</h2>
              <p>
                Fryma e punes sjell rezultate dhe ekip të suksesshëm, jemi çdo
                ditë në rritje dhe disa nga akterët kryesorë janë:
              </p>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay="100">
                  <div className="member-img">
                    <img src={Liman} alt="" className="img-fluid" />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Liman Jashari</h4>
                    <span>Drejtor Gjeneral</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay="200">
                  <div className="member-img">
                    <img src={Bujar} alt="" className="img-fluid" />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Bujar Jashari</h4>
                    <span>Drejtor Ekzekutiv</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay="300">
                  <div className="member-img">
                    <img src={Sherif} alt="" className="img-fluid" />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Sherif Gashi</h4>
                    <span>Drejtor Kontabiliteti</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay="400">
                  <div className="member-img">
                    <img src={Nimi} alt="" className="img-fluid" />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Ngadhnjim Gashi</h4>
                    <span>IT Konsultant</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Kontakti</h2>
              <p>
                Mundohemi të jemi shumë të shpejtë në pergjigje, sepse klienti
                është vler e shtuar e kompanis, andaj ne kemi të gjitha mjetet e
                komunikimit.
              </p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bi bi-geo-alt"></i>
                      <h3>Lokacioni</h3>
                      <p>Lagjja Arberia(ish Dragodan), Prishtinë</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bi bi-envelope-check"></i>
                      <h3>Emaili</h3>
                      <p>info@arberia-kos.com</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bi bi-telephone-inbound"></i>
                      <h3>Na telefono</h3>
                      <p>038 xx xx xx</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Emri Juaj"
                        required
                      />
                    </div>
                    <div className="col form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Emaili juaj"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Shkruani Mesazhin tuaj"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Duke u procesuar</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Mesazhi eshte derguar, faleminderit!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Dërgo mesazhin</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Visualcore</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="#" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>

            <a href="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};
