import React from "react";

export const Post = ({ image, text1, text2, show }) => {
    
    if(!show) return

    return <>
        <div
            className="col-lg-4 col-md-6 portfolio-item filter-app posi"

        >
            <div className="portfolio-wrap">
                <img src={image} alt="" className="img-fluid" />
                <div className="portfolio-info">
                    <h4>{text1}</h4>
                    <p>{text2}</p>
                    <div className="portfolio-links">
                        <a
                            href="assets/img/portfolio/portfolio-1.jpg"
                            data-gallery="portfolioGallery"
                            className="portfolio-lightbox"
                            title="App 1"
                        >
                            <i className="bx bx-plus"></i>
                        </a>
                        <a href="portfolio-details.html" title="More Details">
                            <i className="bx bx-link"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
}